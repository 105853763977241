<template>
    <main>
        <figure>
            <img :src="`/assets/images/home/${activeOrg}.webp`" alt="company_display">
        </figure>
        <div class="bg-color" v-if="isMobile()"></div>
        <section class="data">
            <figure class="bg">
                <img src="@/assets/img/home/elements.webp" alt="additional_elements">
            </figure>
            <span class="version" :class="{ left: activeOrg === 'cheto' }">v.032023.1</span>
            <div class="terms" v-if="activeOrg === 'cheto'">
                <span @click="goTo('https://immera.io/policies')">Terms & Conditions</span>
            </div>
            <LangsButton />
            <Content @logout="handleLogout" />
            <MobileApp v-if="activeOrg === 'cheto'" />
            <Footer />
        </section>
    </main>
</template>

<script>
import LangsButton from '@/components/Ui/Home/LangsButton.vue'
import MobileApp from '@/components/Ui/Home/MobileApp/Index.vue'
import Footer from '@/components/Ui/Home/Footer.vue'
import Content from '@/components/Ui/Home/Content.vue'
import isMobile from '@/mixins/isMobile.js'
import axiosInstance from '@/services/AxiosTokenInstance';
import { mapActions } from 'vuex'
import { LOGOUT_USER } from "@/store/storeconstants";

export default {
    components: {
        LangsButton,
        MobileApp,
        Footer,
        Content
    },

    mixins: [isMobile],

    computed: {
        activeOrg() {
            if(['service.cheto.eu', 'serviceapp.cheto.eu', 'cheto.app.assistance.immera.io'].includes(window.location.hostname)) {
                const link = document.querySelector("link[rel~='icon']");
                link.href = window.location.origin + '/cheto.ico'
                return 'cheto'
            }
            else if(['remoteassistance.altice-empresas.pt'].includes(window.location.hostname)) {
                const link = document.querySelector("link[rel~='icon']");
                link.href = window.location.origin + '/altice.ico'
                return 'altice'
            }
            else if(['remoteassistance.meoempresas.pt', 'meo.app.assistance.immera.io'].includes(window.location.hostname)) {
                const link = document.querySelector("link[rel~='icon']");
                link.href = window.location.origin + '/meo.ico'
                document.title = `Remote Assistance | MEO Empresas`
                return 'meo'
            }
            return 'immera'
        }
    },

    created() {
        this.loadVariables()
        this.checkUser()
    },

    methods: {
        ...mapActions("auth", {
            logout: LOGOUT_USER,
        }),

        loadVariables() {
            let cssLink = document.createElement('link')
            cssLink.rel = 'stylesheet';
            if(this.activeOrg === 'cheto') {
                cssLink.href = '/assets/css/_variables-cheto.css'
                document.head.appendChild(cssLink)
            }
            else if(this.activeOrg === 'altice') {
                cssLink.href = '/assets/css/_variables-altice.css'
                document.head.appendChild(cssLink)
            }
            else if(this.activeOrg === 'meo') {
                cssLink.href = '/assets/css/_variables-altice.css'
                document.head.appendChild(cssLink)
                document.title = `Remote Assistance | MEO Empresas`
            }
        },

        goTo(link) {
            window.open(link, '_blank')
        },

        async checkUser() {
            if (!localStorage.userData || !localStorage.userData.length) {
                return
            }
            
            const response = await axiosInstance.get('get-details')

            if (response.status !== 200 || !response.data.data) {
                this.handleLogout()
                return
            }
        },

        handleLogout() {
            this.logout();
            this.emitter.emit("logout");
            setTimeout(() => {
                location.reload()
            }, 200);
        }
    }
}
</script>

<style lang="scss" scoped>
main {
    width: 100%;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;

    @media only screen and (max-width: 1200px) {
        flex-direction: column;
    }

    .data,
    figure {
        height: 100%;
    }

    figure {
        width: calc(100% - 720px);
        display: flex;
        justify-content: flex-end;
        transition: 0.2s;

        @media only screen and (max-width: 1200px) {
            width: 100%;
            height: 40%;
            position: absolute;
            top: 0;
        }

        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }

    .bg-color {
        width: 100%;
        height: 100%;
        position: absolute;
        bottom: 0;
        right: 0;
        z-index: -1;
        background: var(--primary-color);
    }

    .data {
        width: 720px;
        padding: 50px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        position: relative;
        background: white;

        @media only screen and (max-width: 1200px) {
            width: 95%;
            background: white;
            position: absolute;
            bottom: 0;
            height: 80vh;
            border-top-left-radius: 15px;
            border-top-right-radius: 15px;
        }


        .version {
            display: flex;
            justify-content: center;
            position: absolute;
            z-index: 400;
            color: var(--primary-color);
            font-size: 0.7rem;
            opacity: 0.5;
            bottom: .6rem;

            &.left {
                left: 2rem;
            }
        }

        .terms {
            position: absolute;
            bottom: .6rem;
            right: 2rem;
            color: var(--primary-color);
            z-index: 100;
            cursor: pointer;
            text-decoration: underline;
            font-size: 0.7rem;
        }

        .bg {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;

            img {
                width: 100%;
                height: 100%;
                object-fit: cover;
            }
        }
    }
}
</style>