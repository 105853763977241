<template>
    <footer>
        <span>Produced by</span>
        <figure>
            <img src="@/assets/img/home/full_logo.webp" alt="immera_logo">
        </figure>
    </footer>
</template>

<style lang="scss" scoped>
    footer {
        position: absolute;
        bottom: 50px;
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 0.5rem;

        span {
            font-size: 0.7rem;
            font-weight: 500;
            color: #D1D3D4;
        }

        figure {
            height: 1rem;

            img {
                width: 100%;
                height: 100%;
                object-fit: contain;
            }
        }
    }
</style>