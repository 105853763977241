<template>
    <div class="languages">
        <div class="selected" @click="isOpened = !isOpened">
          <span>{{currentLang}}</span>
          <figure :class="{opened: isOpened}">
              <img src="/assets/images/left-arrow.svg" alt="chevron">
          </figure>
        </div>
        <div v-if="isOpened" class="options">
          <template v-for="l in langs" :key="l.code">
            <span v-if="l.name !== currentLang"> {{l.name}} </span>
          </template>
        </div>
    </div>
</template>

<script>
import langs from '@/assets/json/langs.json'
import isMobile from '@/mixins/isMobile.js'
export default {
    data() {
        return {
            langs,
            isOpened: false,
        }
    },
    
    mixins: [isMobile],

    computed: {
        currentLang() {
            const item = this.langs.filter(el => el.code === this.$i18n.locale)[0]
            return this.isMobile() ? item.code.toUpperCase() : item.name
        }
    },
}
</script>

<style lang="scss" scoped>
.languages {
    position: fixed;
    top: 50px;
    right: 50px;
    user-select: none;
    cursor: pointer;
    z-index: 1;

    .selected {
        display: flex;
        align-items: center;
        gap: 1.5rem;
        border: solid 1px var(--primary-color);
        padding: 10px 25px;
        border-radius: 30px;
        color: var(--primary-color);
        background: white;
        transition: 0.2s;

        &:hover {
            border: solid 1px transparent;
            color: white;
            background: var(--primary-color);
        }

        figure {
            width: 10px;
            height: 10px;
            transform: rotate(270deg);
        }
    }
}
</style>