<template>
    <article>
        <div class="logo">
            <template v-if="activeOrg === 'immera'">
                <div class="top">
                    <figure class="icon">
                        <img src="/assets/images/logo/icon.svg" alt="icon">
                    </figure>
                    <figure class="text" v-if="!isMobile()">
                        <img src="/assets/images/logo/logo.svg" alt="icon">
                    </figure>
                </div>
                <figure class="typo">
                    <img src="/assets/images/logo/text.svg" alt="typo">
                </figure>
            </template>
            <template v-else-if="activeOrg === 'cheto'">
                <figure class="custom">
                    <img src="/assets/images/home/logo_cheto.png" alt="logo_cheto">
                    <span>Service App</span>
                </figure>
            </template>
            <template v-else-if="activeOrg === 'altice'">
                <figure class="custom">
                    <img src="/assets/images/home/logo_altice.webp" alt="logo_altice">
                    <span>Remote Assistance</span>
                </figure>
            </template>
            <template v-else-if="activeOrg === 'meo'">
                <figure class="custom">
                    <img src="/assets/images/home/logo_meo.png" alt="logo_meo">
                    <span>Remote Assistance</span>
                </figure>
            </template>
        </div>
        <div class="tab">
            <transition name="slide-left">
                <Login :primaryColor="primaryColor" v-if="login" @success="; (login = false), orgs = true" />
                <Orgs :primaryColor="primaryColor" v-else-if="orgs" @close="orgs = false"
                    @clients="; (orgs = false), clients = true" />
                <Clients :primaryColor="primaryColor" v-else-if="clients" @close="clients = false" />
                <div v-else-if="goToDash" class="logged">
                    <span class="dashboard" @click="goToDashboard">go to dashboard</span>
                    <span class="logout" @click="$emit('logout')">log out</span>
                </div>

            </transition>
        </div>
    </article>
</template>

<script>
import Login from '@/components/Auth/Login.vue'
import Orgs from '@/components/Auth/Orgs.vue'
import Clients from '@/components/Auth/Clients.vue'
import isMobile from '@/mixins/isMobile.js'
import axiosInstance from '@/services/AxiosTokenInstance';
export default {

    components: {
        Login,
        Orgs,
        Clients
    },

    mixins: [isMobile],

    data() {
        return {
            login: false,
            orgs: false,
            clients: false,
            goToDash: false,
            org: localStorage.organization ? JSON.parse(localStorage.organization) : undefined,
            client: localStorage.client ? JSON.parse(localStorage.client) : undefined,
        }
    },

    computed: {
        activeOrg() {
            if(['service.cheto.eu', 'serviceapp.cheto.eu', 'cheto.app.assistance.immera.io'].includes(window.location.hostname)) return 'cheto'
            else if(['remoteassistance.altice-empresas.pt'].includes(window.location.hostname)) return 'altice'
            else if(['remoteassistance.meoempresas.pt', 'meo.app.assistance.immera.io'].includes(window.location.hostname)) return 'meo'
            return 'immera'
        }
    },

    methods: {
        async goToDashboard() {
            if(this.client) {
                await axiosInstance.post(`store-user-login`, {org_id: this.org.id, client_id: this.client.id})
            }
            else {
                await axiosInstance.post(`store-user-login`, {org_id: this.org.id})
            }

            this.$router.push('/data-structure')
        }
    },

    mounted() {
        if (!localStorage.getItem('userData')) {
            this.login = true
        }
        else if (localStorage.getItem('userData') && !localStorage.getItem('organization')) {
            this.orgs = true
        }
        else if(localStorage.getItem('organization') && localStorage.getItem('U_P')) {
            const user = JSON.parse(localStorage.getItem('U_P'))
            if (!localStorage.getItem('client') && !user.role.id) this.clients = true
            else if (localStorage.getItem('client') || user.role.id) this.goToDash = true
        }
    }
}
</script>

<style lang="scss" scoped>
.slide-left-enter-active {
    animation: slide-left 0.2s forwards;
}

.slide-left-leave-active {
    animation: slide-right 0.2s forwards;
}

article {
    position: absolute;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 2rem;
    transition: 0.2s;
    width: 100%;
    height: 100%;

    @media only screen and (max-width: 1200px) {
        gap: 1rem;
    }

    .logo {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: flex-end;
        gap: 1rem;
        width: 75%;
        height: 35%;

        .custom {
            width: 50%;
            display: flex;
            flex-direction: column;
            align-items: center;
            gap: 1rem;

            span {
                color: var(--primary-color);
                font-weight: 600;
                font-size: 1.7rem;
                width: 80vw;
                text-align: center;
            }
        }

        .top {
            display: flex;
            align-items: center;
            gap: 3rem;

            figure {
                height: 5rem;

                img {
                    width: 100%;
                    height: 100%;
                }
            }
        }

        .typo {
            height: 1.5rem;

            img {
                width: 100%;
                height: 100%;
            }
        }

    }

    .tab {
        height: 50%;
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 2rem;
        padding: 20px;
        position: relative;

        @media only screen and (max-width: 1200px) {
            gap: 1rem;
        }

        .logged {
            position: absolute;
            top: calc(35% - 1.5rem);
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;

            .dashboard {
                height: 3rem;
                background: var(--primary-color);
                color: white;
                display: flex;
                justify-content: center;
                align-items: center;
                padding: 0 25px;
                border-radius: 30px;
                text-transform: capitalize;
                user-select: none;
                cursor: pointer;
                transition: 0.2s;

                &:hover {
                    background: var(--primary-hover);
                }
            }

            .logout {
                color: $red;
                display: flex;
                justify-content: center;
                align-items: center;
                padding: 4px 12px;
                font-size: 8px;
                text-transform: uppercase;
                width: fit-content;
                border-radius: 2px;
                margin-top: 12px;
                cursor: pointer;
                user-select: none;
                transition: 0.2s;

                &:hover {
                    background: $red;
                    color: white;
                }


            }

        }



        .slogan {
            color: #D1D3D4;
            position: absolute;
            bottom: 15%;

            @media only screen and (max-width: 1200px) {
                display: none;
            }
        }
    }
}

@keyframes slide-left {
    0% {
        opacity: 0;
        transform: translateX(100px);
    }

    100% {
        opacity: 1;
        transform: translateX(0px);
    }
}

@keyframes slide-right {
    0% {
        opacity: 1;
        transform: translateX(0px);
    }

    100% {
        opacity: 0;
        transform: translateX(-100px);
    }
}
</style>