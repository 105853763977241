<template>
    <span class="title">Select {{type}}:</span>
    <div class="data">
        <div class="selected" :class="{opened: isOpened}" @click="isOpened = !isOpened">
            <div class="org-data"  v-if="data.length">
                <figure>
                    <img v-if="data[selectedItem].logo" :src="imgURL + data[selectedItem].logo" alt="org_logo">
                    <span v-else class="no-logo">{{data[selectedItem].name.charAt(0)}}</span>
                </figure>
                <span>{{data[selectedItem].name}}</span>
            </div>
            <span v-else>
                <div class="loading">
                    <span class="loader"></span>
                </div>
            </span>
            <figure class="arrow" :class="{opened: isOpened}">
                <img src="/assets/images/left-arrow.svg" alt="chevron">
            </figure>
        </div>
        <div class="to-select" v-if="isOpened">
            <template v-for="(item, i) in data" :key="i">
                <div class="org-data"  v-if="data.length" @click="selectedItem = i">
                    <figure>
                        <img v-if="item.logo" :src="imgURL + item.logo" alt="org_logo">
                        <span v-else class="no-logo">{{item.name.charAt(0)}}</span>
                    </figure>
                    <span>{{item.name}}</span>
                </div>
            </template>
        </div>
    </div>
    <div class="confirm">
        <span @click="sendOrg">confirm</span>
    </div>
    
</template>

<script>
export default {
    props: {
        data: {
            type: Array,
            required: true
        },
        type: {
            type: String,
            required: true
        }
    },

    emits: ['sendOrg'],

    data() {
        return {
            imgURL: process.env.VUE_APP_DO_SPACES,
            selectedItem: 0,
            isOpened: false
        }
    },

    methods: {
        sendOrg() {
            this.$emit('sendOrg', this.data[this.selectedItem])
        }
    }
}
</script>

<style lang="scss" scoped>
.title {
    font-weight: 500;
    margin-bottom: 0.5rem;
    text-transform: capitalize;
}

.data {
    position: relative;

    .selected {
        display: flex;
        justify-content: space-between;
        align-items: center;
        border: solid 1px $grey;
        padding: 10px 20px;
        border-radius: 4px;
        background: white;
        width: 100%;
        transition: 0.2s;

        &.opened {
            .org-data {
                opacity: 0.5;
            }
        }

        .org-data {
            display: flex;
            align-items: center;
            gap: 0.5rem;

            figure {
                width: 25px;
                height: 25px;
                border-radius: 50%;
                overflow: hidden;
                display: flex;
                justify-content: center;
                align-items: center;

                img {
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                }

                .no-logo {
                    width: 100%;
                    height: 100%;
                    background: var(--primary-color);
                    color: white;
                    font-weight: 500;
                    user-select: none;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                }
            }
        }

        .arrow {
            width: 10px;
            height: 10px;
            transform: rotate(270deg);
            opacity: 0.3;
            transition: 0.2s;
            display: flex;
            justify-content: flex-end;
            align-items: center;

            &.opened {
                transform: rotate(90deg);
            }
        }

        .loading {
            height: unset;
            background: none;

            .loader {
                border: 4px solid #f3f3f3;
                border-top: 4px solid var(--primary-color);
                width: 25px;
                height: 25px;
            }
        }
    }

    .to-select {
        position: absolute;
        display: flex;
        flex-direction: column;
        border: solid 1px $grey;
        border-top: transparent;
        width: 100%;
        top: 45px;
        background: white;
        max-height: 200px;
        overflow: auto;
        .org-data {
            display: flex;
            align-items: center;
            gap: 0.5rem;
            padding: 10px 20px;

            &:not(:last-of-type) {
                border-bottom: solid 1px $grey;
            }

            figure {
                width: 25px;
                height: 25px;
                border-radius: 50%;
                overflow: hidden;
                display: flex;
                justify-content: center;
                align-items: center;

                img {
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                }

                .no-logo {
                    width: 100%;
                    height: 100%;
                    background: var(--primary-color);
                    color: white;
                    font-weight: 500;
                    user-select: none;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                }
            }
        }
    }
}

.confirm {
    margin-top: 5rem;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;

    span {
        width: 100%;
        background: var(--primary-color);
        color: white;
        border-radius: 50px;
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 15px 0;
        text-transform: uppercase;
        user-select: none;
        cursor: pointer;
        transition: 0.2s;

        &:hover {
            background: var(--primary-hover);
        }
    }
    
}

</style>