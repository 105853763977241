<template>
    <div class="mobile-app">
        <span>Download the app on</span>
        <div class="buttons">
            <span @click="goTo('https://play.google.com/store/apps/details?id=com.remote_service')">google play</span>
            <span @click="goTo('https://apps.apple.com/pt/app/cheto-service/id1632434658')">app store</span>
        </div>
    </div>
</template>

<script>
export default {
    methods: {
        goTo(link) {
            window.open(link, '_blank')
        },
    }
}
</script>

<style lang="scss" scoped>
    .mobile-app {
        position: absolute;
        width: 100%;
        height: 120px;
        bottom: 100px;
        gap: 1rem;
        user-select: none;
        color: #D1D3D4;
        font-size: 12px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: flex-start;

        @media only screen and (max-width: 1800px) {
            bottom: 50px;
        }

        @media only screen and (max-width: 1700px) {
            bottom: 100px;
        }

        @media only screen and (max-width: 768px) {
            height: unset;
        }

        .buttons {
            display: flex;
            width: 95%;
            max-width: 500px;
            justify-content: center;
            gap: 2rem;

            span {
                border: solid 1px $red;
                padding: 10px 50px;
                border-radius: 50px;
                color: $red;
                cursor: pointer;
                text-transform: uppercase;
                transition: 0.2s;

                @media only screen and (max-width: 768px) {
                    padding: 10px 25px;
                }

                &:hover {
                    background: $red;
                    color: white;
                } 
            }
        }
    }
</style>