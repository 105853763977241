<template>
    <section>
        <main>
            <header>
                <h4>Organizations</h4>
            </header>
            <template v-if="!isMobile()">
                <article>
                    <template v-if="loaded && organizationList.length ">
                        <template v-for="(org, i) in organizationList" :key="i">
                            <div class="org" @click="saveOrganization(org.organization.name, org.organization.id, org.organization.logo)">
                                <figure>
                                    <img v-if="org.organization.logo" :src="imgURL + org.organization.logo" alt="">
                                    <span v-else class="no-logo">
                                        {{org.organization.name.charAt(0)}}
                                    </span>
                                </figure>
                                <span class="name">{{org.organization.name}}</span>
                            </div>
                        </template>
                    </template>
                    <template v-else-if="loaded && !organizationList.length">
                        <div class="no-org">
                            <span>You are not assigned to any organization.</span>
                            <span class="logout" @click="handleLogout">logout</span>
                        </div>
                    </template>
                    <template v-else-if="!loaded">
                        <div class="loading">
                            <span class="loader"></span>
                        </div>
                    </template>
                </article>
            </template>
            <template v-else>
                <MobileSelect :data="organizationList.map(el => el.organization)" :type="'organization'" @sendOrg="handleFromMobile" />
            </template>
        </main>
    </section>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import { GET_USER_PROFILE_ACTION, GET_USER_PROFILE_GETTER, LOGOUT_USER } from "@/store/storeconstants";
import axiosInstance from '@/services/AxiosTokenInstance';
import isMobile from '@/mixins/isMobile.js'
import MobileSelect from './MobileSelect.vue'
export default {
    props: {
        primaryColor: {
            type: String
        }
    },
    data() {
        return {
            organizationList: [],
            imgURL: process.env.VUE_APP_DO_SPACES,
            loaded: false
        }
    },

    mixins: [isMobile],

    components: {
        MobileSelect
    },

    methods: {
        ...mapActions("auth", {
        logout: LOGOUT_USER,
        }),
        ...mapActions("account", {
            getUserData: GET_USER_PROFILE_ACTION,
        }),
        ...mapGetters("account", {
            getUserProfile: GET_USER_PROFILE_GETTER,
        }),

        async getOrgs() {
            const response = await axiosInstance.get('organizations-list').finally(() => this.loaded = true)

            if (response) {
                this.organizationList = response.data.data
            }
        },

        async saveOrganization(name, id, logo) {
            let organizationData = {
                id: id,
                name: name,
                logo: logo,
            };
            localStorage.setItem("organization", JSON.stringify(organizationData));
            await this.getUserData()

            const role = this.getUserProfile().role?.id || null;
            if (!role) this.$emit('clients')
            else {
                await axiosInstance.post(`store-user-login`, {org_id: id})
                this.$router.push("/data-structure");
            }
        },

        handleLogout() {
            this.logout();
            this.emitter.emit("logout");
            this.$router.replace("/");
            this.$emit('close')
        },

        handleFromMobile(data) {
            this.saveOrganization(data.name, data.id, data.logo)
        }
    },

    mounted() {
        this.getOrgs()
    }
}
</script>


<style lang="scss" scoped>
section {
    width: 70%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    position: relative;
    gap: 0.5rem;
    position: absolute;

    @media only screen and (max-width: 1200px) {
        width: 50%;
    }
    @media only screen and (max-width: 768px) {
        width: 80%;
    }
    main {
        background: white;
        width: 100%;
        max-width: 500px;
        border-radius: 8px;
        overflow: hidden;
        box-shadow: 0px 1px 3px $grey;
        display: flex;
        flex-direction: column;
        position: relative;
        transition: 0.2s;
        z-index: 5;

        @media only screen and (max-width: 1200px) {
            box-shadow: unset;
            background: none;
            border-radius: 0px;
            overflow:unset;
        }
        header {
            padding: 20px;
            width: 100%;
            display: flex;
            justify-content: center;
            box-shadow: 0px 1px 3px $grey;

            @media only screen and (max-width: 1200px) {
                display: none;
            }

            h4 {
                font-size: 1rem;
                text-transform: uppercase;
                margin: 0;
                transition: 0.2s;
                color: var(--primary-color);
            }
        }
        article {
            padding: 20px;
            display: flex;
            width: 100%;
            flex-wrap: wrap;
            gap: 1.25rem;

            .no-org, .org {
                display: flex;
                flex-direction: column;
                align-items: center;
                gap: 0.5rem;
            }

            .no-org {
                width: 100%;
                .logout {
                    cursor: pointer;
                    user-select: none;
                    background: $red;
                    padding: 0.5rem 1rem;
                    color: white;
                    text-transform: capitalize;
                    transition: 0.2s;

                    &:hover {
                        background: $red-hover;
                    }
                }
            }

            .org {
                cursor: pointer;
                user-select: none;

                figure {
                    width: 75px;
                    height: 75px;
                    border-radius: 50%;
                    overflow: hidden;
                    img {
                        width: 100%;
                        height: 100%;
                        object-fit: cover;
                    }

                    .no-logo {
                        width: 100%;
                        height: 100%;
                        color: white;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        font-weight: 500;
                        font-size: 1.5rem;
                    }
                }
                

                .name {
                    font-weight: 500;
                }
            }
        }
    }
}

.loading {
    padding: 5px 0;
    background: none;
    height: unset;

    .loader {
        border: 3px solid #f3f3f3;
        border-top: 3px solid var(--primary-color);
        width: 20px;
        height: 20px;
    }
}
</style>